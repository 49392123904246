.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: space-between;
  width: 80%;
  margin: auto;
  margin-top: 3%;
}

.card:hover {
  cursor: pointer; 
}

.golfScore {
  font-size: 50px;
}

.good {
  color: #02a62d;
}

.bad {
  color: #b50505;
}

.decent {
  color: #f0870e
}

.add-cd-wrapper {
  width: 40%;
  margin: auto;
}

.cd-control-bar {
  display: flex;
  justify-content: space-evenly;
  align-content: space-between;
  width: 20%;
  margin: auto;
  margin-top: 3%;
}

.dead-cd-switch {
  padding-top: 1.1rem;
}

.sort-btn {
  margin-top: .75rem;
  display: block;
  width: auto;
}

.trip-page-container {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  margin-top: 2%;
}
#main-container {
    margin-left: auto;
    margin-right: auto;
    display: flex;
  flex-direction: column;
  padding: 24px 57px 44px 57px;
}

#columns {
    align-self: stretch;
  margin: 6px 35px 0 0;
}


#small-container {
    gap: 20px;
  display: flex;
  margin-bottom: 5%;
}

#column1 {
    display: flex;
  flex-direction: column;
  line-height: normal;
  width: 41%;
  margin-left: 0px;
}

#photo {
    display: flex;
  width: 445px;
  max-width: 100%;
  flex-direction: column;
  margin: 0 auto;
  border-style: solid;
  height: 410px;
  position:relative;
}

#column2 {
    display: flex;
  flex-direction: column;
  line-height: normal;
  width: 59%;
  margin-left: 20px;
  margin-left: 0px;
}

#column3 {
    display: flex;
  flex-direction: column;
  margin: auto 0;
  margin-left: 20px;
}

#name {
    height: 50%;
    font-size: 50px;
    margin-bottom: 8%
}


#expenseTable {
    margin-top: 3%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3%;
    font-size: 18px;
    display:flex;
    justify-content: center;
    flex-direction: column;
}

.customCol {
    vertical-align: middle;
    flex: 1 0;
    line-height: 2;
}

.topLabel {
    font-size: 18px;
}
.card{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: 40%;
    display: inline-block;
    margin: 3% 5%;
}

.textContainer {
    padding: 2px 16px;
}